import {Component} from "react";
import React from "react";
import ApiClient from "../../../ts/utils/ApiClient";
import apiClient from "../../data/apiClient";
import IntlMessages from "appUtil/IntlMessages";
import Tooltip from "components/Tooltip";
import moment from "moment-timezone";
import connect from "react-redux/es/connect/connect";
import {Button, ModalHeader, ModalBody, ModalFooter} from "reactstrap";

export class HealthTrafficLight extends Component {

    constructor(props) {
        super(props);
        this.reload = this.reload.bind(this);
        this.monitors = {
            // DS: {
            //     apiClient: DS_URL ? new ApiClient(DS_URL) : null,
            //     endpoint: '/status',
            //     check: data => data && data.status && data.status === 'ok'
            // },
            // BE: {
            //     apiClient: BE_URL ? new ApiClient(BE_URL) : null,
            //     endpoint: '/health',
            //     check: data => data && data.status && data.status === 'up'
            // },
            // FE: {
            //     apiClient: FE_URL ? new ApiClient(FE_URL) : null,
            //     endpoint: '/health',
            //     check: data => data && data.status && data.status === 'UP'
            // },
            GDB: {
                apiClient: apiClient,
                endpoint: '/api/v1/status',
                check: data => data && data.status && data.status === 'ok',
                getVersion: data => data && data.version ? data.version : null,
                getVersionDate: data => data && data.versionDate ? data.versionDate : null,
                reloadPageOnVersionChanged: true,
            },
        };
        let monitoringList = [];
        for(const key in this.monitors) {
            if(this.monitors[key].apiClient) {
                monitoringList.push({key, status: false});
            }
        }
        this.state = {
            monitoringList
        };
    }

    componentDidMount() {
        this.state.monitoringList.forEach(monitor => {
            this.checkMonitor(monitor.key);
        });
    }

    checkMonitor(key) {
        let monitor = this.state.monitoringList.filter(m => m.key === key).first;
        this.monitors[key].apiClient.get(this.monitors[key].endpoint).then(response => {
            monitor.status = this.monitors[key].check(response.data);
            if (this.monitors[key].getVersion) {
                const version = this.monitors[key].getVersion(response.data);
                if (this.monitors[key].reloadPageOnVersionChanged && monitor.version && monitor.version !== version) {
                    this.showModalForReloadPage(key, monitor.version, version);
                }
                monitor.version = this.monitors[key].getVersion(response.data);
            }
            if (this.monitors[key].getVersionDate) {
                monitor.versionDate = this.monitors[key].getVersionDate(response.data);
            }
            monitor.timeout = setTimeout(this.checkMonitor.bind(this, key), 60000);
            this.forceUpdate();
        }).catch(reason => {
            monitor.status = false;
            monitor.timeout = setTimeout(this.checkMonitor.bind(this, key), 60000);
            this.forceUpdate();
        })
    }

    showModalForReloadPage(instance, oldVersion, newVersion) {
        this.props.modalService.show(
            <ModalHeader><IntlMessages id={"version.outdated"}/></ModalHeader>,
            <ModalBody>
                <p className="body-14-regular">
                    {instance} <IntlMessages id={"version.updated"}/> <span className="code-text">{oldVersion}</span> <i className="far fa-arrow-right-long mx-start-1 mx-end-1" /> <span className="code-text">{newVersion}</span>.
                </p>
                <p className="body-14-regular">
                    <IntlMessages id={"need.refresh.page"}/>
                </p>
            </ModalBody>, <ModalFooter>
                <button className={"system-button size-s minimal"} onClick={this.props.modalService.close}>
                    <IntlMessages id="common.cancel"/>
                </button>
                <button className={"system-button size-s"} onClick={this.reload}>
                    <IntlMessages id="refresh"/>
                </button>
            </ModalFooter>, "outdated-version-modal", true);
    }

    reload() {
        window.location.reload();
    }

    componentWillUnmount() {
        this.state.monitoringList.forEach(monitor => {
            if (monitor.timeout) {
                clearTimeout(monitor.timeout);
            }
        });
    }

    render() {
        return (
            <div className="health-traffic-light d-flex align-items-center">
                {this.state.monitoringList.map((monitor, monitorIndex) => <span key={monitorIndex} className="d-flex">
                    <span style={{color: monitor.status ? '#5FB98F' : '#F75F5F'}} className="monitor-status">
                        <Tooltip className={"monitor-tooltip"} placement="left">
                            {monitor.version &&
                            <small>{monitor.version}</small>}
                            {monitor.versionDate &&
                            <small> - {moment(monitor.versionDate).format('DD/MM/YYYY - HH:mm')}</small>}
                            <small> - <IntlMessages
                                id={monitor.status ? "monitor.status.on" : "monitor.status.off"}/></small>
                        </Tooltip>
                    </span>
                </span>)}
            </div>
        );
    }
}

const mapStateToProps = ({services}) => {
    const modalService = services.modal;
    return {modalService};
};
export default connect(mapStateToProps)(HealthTrafficLight);