import BaseService from "./BaseService";

export default class NotificationService extends BaseService {

    public getMessages() {
        return this.client.get('/notification/messages');
    }

    public getReminders() {
        return this.client.get('/api/v1/notification/reminders');
    }

    public modifyReminder(data: any) {
        if (data.id) {
            return this.client.put(`/api/v1/notification/reminders/${data.id}`, data)
        }
        return this.client.post('/api/v1/notification/reminders', data);
    }

    public deleteReminder(reminderId: any, allVersion?: boolean) {
        let endpoint = `/api/v1/notification/reminders/${reminderId}`;
        if (allVersion) {
            endpoint += '?allVersion=true'
        }
        return this.client.delete(endpoint);
    }
}